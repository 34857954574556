
import Vue from 'vue';

export default Vue.extend({
    name: 'EditCurrency',

    props: {
        modalType: {
            type: String,
            required: true,
        },
        currencyData: {
            type: Object,
            required: false,
        },
        editStatus: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currencyErrors: {
                fullname: null as null | string,
                code: null  as null | string,
                symbol: null  as null | string,
                course: null  as null | string,
                sortNumber: null,
                dreamLimit: null,
            },
            fullname: null  as null | string,
            code: null  as null | string,
            symbol: null as null | string,
            course: null as null | string,
            sortNumber: null,
            status: false,
            dreamLimit: null,
        };
    },

    computed: {
        isSubmitButtonDisabled(): boolean {
            const errors: Array<string | null> = Object.values(this.currencyErrors);
            for (let i = 0; i < errors.length; i++) {
                if (errors[i]) return true;
            }

            return (
                !this.code
                || !this.symbol
                || !this.course
                || !(this.sortNumber || this.sortNumber === 0)
                || !this.dreamLimit
            );
        },
    },

    mounted() {
        if (this.currencyData) {
            this.fullname = this.currencyData.name;
            this.code = this.currencyData.code;
            this.symbol = this.currencyData.symbol;
            this.course = this.currencyData.course;
            this.sortNumber = this.currencyData.sort_number;
            this.status = this.currencyData.is_active;
            this.dreamLimit = this.currencyData.dream_limit;
        }
    },

    methods: {
        showModal() {
            this.$emit('showModal');
        },
        cancelModal() {
            this.$emit('cancelModal');
        },
        async submitForm() {
            if (this.isSubmitButtonDisabled) return;

            this.$emit('submitEdit', {
                name: this.fullname,
                code: this.code,
                symbol: this.symbol,
                course: this.course ? +this.course : null,
                sort_number: this.sortNumber,
                is_active: this.status,
                dream_limit: this.dreamLimit,
            });
        },

        validateCode() {
            if (this.code) {
                this.currencyErrors.code = /^[A-Z]{3}$/
                    .test(this.code.toUpperCase()) ? null : 'Unvalid code format';
            }
        },

        validateSymbol() {
            this.currencyErrors.symbol = this.symbol ? null : 'Unvalid symbol format';
        },

        validateNumber(errorName) {
            this.currencyErrors[errorName]
                = Number.isFinite(this[errorName]) && this[errorName] > 0 ? null : 'Unvalid format';
        },

        validateCourse() {
            const float = `${this.course}`.split('.')[1];
            this.currencyErrors.course = float && float.length > 2
                ? 'Non-integer part of course must be shorter than 3 symbols' : null;
        },

        validateName() {
            if (this.fullname && this.fullname.length < 3) {
                this.currencyErrors.fullname = 'Fullname must be equal or longer than 3 letters';
                return;
            }
            
            if (this.fullname && this.fullname.length > 30) {
                this.currencyErrors.fullname = 'Fullname must be equal or shorter than 20 letters';
                return;
            }
        },
    },
});
