

// @ts-nocheck

import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import { currencyActions } from '@/store/modules/currency/names';
import EditCurrency from './EditCurrency.vue';
import { ComposedState } from '@/types';
import { ICurrencyData } from '@/store/modules/currency/types';

export default Vue.extend({
    name: 'CurrenciesList',

    components: { EditCurrency },

    data() {
        return {
            editingId: null as null | number,
            isEditModalShown: false,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            allCurrencies: (state: ComposedState) => state.currency.allCurrencies,
            editStatus: (state: ComposedState) => state.currency.editStatus,
        }),

        currentEditingCurrency() {
            if (!this.editingId) return null;

            return this.allCurrencies.find((currency) => currency.id === this.editingId);
        },

        modalType() {
            return this.currentEditingCurrency ? 'Edit' : 'Add';
        },
    },

    mounted() {
        this.getAllCurrencies();
    },

    methods: {
        ...mapActions({
            getAllCurrencies: currencyActions.getAllCurrencies,
            updateCurrency: currencyActions.updateCurrency,
        }),
        cancelModal() {
            this.isEditModalShown = false;
            this.editingId = null;
        },
        showModal(cur: ICurrencyData, isEditing: boolean) {
            this.isEditModalShown = true;
            isEditing
                ? this.editingId = cur.id
                : this.editingId = null;
        },
        async submitEditCurrency(currency) {
            if (this.editingId) {
                await this.updateCurrency({
                    data: {
                        ...currency,
                        id: this.editingId,
                    },
                    type: 'update',
                });
            } else {
                await this.updateCurrency({
                    data: currency,
                    type: 'create',
                });
            }

            if (this.editStatus === 'success') {
                this.editingId = null;
                this.isEditModalShown = false;
            }
        },
    },
});
